<template>
    <el-date-picker v-model="dataValue" type="monthrange" :format="dateFormat" align="left" unlink-panels
        range-separator="-" start-placeholder="Start month" end-placeholder="End month" :picker-options="options"
        :disabled="disabled">
    </el-date-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker"; //you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
    components: {
        DateRangePicker,
    },
    props: {
        selectedDate: {
            type: Array,
            required: true,
        },
        dateFormat: {
            type: String,
            default: "MMM yyyy"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableDateBefore: {
            type: String
        }
    },
    data() {
        return {
            options: {
                shortcuts: [{
                    text: 'This month',
                    onClick(picker) {
                        picker.$emit('pick', [new Date(), new Date()]);
                    }
                }, {
                    text: 'This year',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().getFullYear(), 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 6 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 6);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 12 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 12);
                        picker.$emit('pick', [start, end]);
                    }
                }],
                disabledDate: (date) => {
                    const currentDate = moment(date).format('YYYY-MM');
                    const dateOrigin = moment(this.disableDateBefore).format('YYYY-MM');

                    return moment(currentDate).isBefore(dateOrigin, 'month');
                }
            },
            moment: moment
        };
    },
    computed: {
        dataValue: {
            get: function () {
                return (this.selectedDate) ? this.selectedDate : null
            },
            set: function (value) {
                this.$emit('change', value)
            }
        }
    }
};
</script>
<style lang="css">
.el-date-editor {
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    width: 100% !important;
}

.el-date-editor .el-picker-panel__sidebar,
.el-date-editor i,
.el-date-editor input,
.el-date-editor span {
    color: #0E4333 !important;
    font-family: 'Inter';
}

.el-date-editor .el-range-separator {
    padding: 0;
    margin: 0;
    text-align: center;
    width: 10px;
}

.el-picker-panel {
    z-index: 60000 !important;
}

.el-picker-panel .el-picker-panel__shortcut {
    color: #0E4333 !important;
    font-family: 'Inter';
    font-size: 12px;
    padding: 4px 10px;
}

.el-picker-panel .el-picker-panel__shortcut:hover {
    background-color: #edfff9;
    font-weight: bold;
}

.el-picker-panel .in-range div {
    background-color: #edfff9 !important;
}

.el-picker-panel .in-range.start-date .cell,
.el-picker-panel .in-range.end-date .cell {
    background-color: #074230;
    color: #fff !important;
}

.el-picker-panel td .cell:hover {
    color: #074230;
    font-weight: bold;
}

.el-picker-panel .today .cell {
    color: #074230 !important;
}

.el-picker-panel__icon-btn:hover {
    color: #074230;
    font-weight: bold;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .el-picker-panel {
        width: 100% !important;
    }

    .el-picker-panel__sidebar {
        display: none !important;
    }

    .el-picker-panel__body {
        margin-left: 0 !important;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .el-date-range-picker .el-picker-panel__body {
        min-width: auto;
    }

    .el-date-range-picker .el-picker-panel__body .el-month-table td .cell {
        width: auto;
    }
}
</style>
